<template>
	<div>
		
		<div class="row">
			<div class="col-12">
				<h6 class="oml-h6Title">{{ $t('WhiteLabel') }}</h6>
				
				<div class="p-desc">
						<p>{{ $t('WLDesc') }}</p>
					</div>
			</div>
			<div class="event_processing" v-show="Loading">
				<!--i class="fa fa-spinner fa-spin fa-3x fa-fw"></i-->
				<img src="/img/ajax-loader.gif" />
			</div>
			
			<div class="col-12 mx-auto">
				<!--form @submit.prevent="SubmitAction" method="post"-->
				<div v-if="DomainAgency" class="whiteLabel-table mt-2">
					<div class="row whiteLabel-thead">
						<div class="col-6 whiteLabel-th"><small>{{ $t('Domain') }}</small></div>
						<div class="col-3 whiteLabel-th"><small>{{ $t('Status') }}</small></div>
						<div class="col-3 whiteLabel-th text-center"><small>{{ $t('Action') }}</small></div>
					</div>
					<div class="row whiteLabel-tr">
						<div class="col-6 whiteLabel-td my-auto"><small>{{ AgencyArray.domain_name }}</small></div>
						<div class="col-3 whiteLabel-td my-auto">
							<small class="badge badge-warning" v-if="AgencyArray.domain_status == ''">{{ $t('Checking') + '...' }}</small>
							<small class="badge badge-success" v-else-if="AgencyArray.domain_status == 'Success'" style="opacity: 0.8;">{{ $t('Success') }}</small>
							<small class="badge badge-secondary" v-else style="opacity: 0.8;">{{ $t('Pending') }}</small>
						</div>
						<div class="col-3 whiteLabel-td text-center">
							
							<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
								<template v-slot:button-content>
									<i class="fa fa-ellipsis-v dropdown-icon"></i>
								</template>
								<b-dropdown-item @click="DeleteDomain"><i class="fa fa-trash pr-1"></i> {{ $t('Delete') }}</b-dropdown-item>
								<b-dropdown-item @click="DomainAgency = false"><i class="fa fa-edit pr-1"></i> {{ $t('Edit') }}</b-dropdown-item>
								<b-dropdown-item @click="TakeALook()"><i class="fa fa-eye pr-1"></i> {{ $t('View') }}</b-dropdown-item>
								<b-dropdown-item @click="Reconfig()"><i class="fa fa-wrench pr-1"></i> {{ $t('Reconfig') }}</b-dropdown-item>
							</b-dropdown>
							
						</div>
					</div>
				</div>
				<div v-else>
					
					<div class="row mt-4" v-if="AgencyArray.domain_status != ''">
						<div class="col-6">
							<span class="btn btn-icons" @click="DomainAgency = true" style=" width: 90px; font-size: 14px; border-radius: 25px; padding: 10px; ">
								<i class="fa fa-angle-left"></i> {{ $t('Back') }}
							</span>
						</div>
						<div class="col-6 text-right">
							<span class="btn btn-icons" @click="DeleteDomain">
								<i class="fa fa-trash text-danger"></i>
							</span>
						</div>
					</div>
					
					<div class="fullWidth eight columns px-4">
						
						<ul class="cbp_tmtimeline">
							<li>
								<div :class="['cbp_tmicon', StepCheck.Domain || Accordion.Domain ? 'cbp_tmicon-check' : '']">
									<i class="fa fa-check" v-if="StepCheck.Domain"></i>
									<span v-else>1</span>
								</div>
								<div class="cbp_tmlabel fadeInRight animated">
									
									<div class="row information-agency mt-3">
										<b-card no-body class="col-12 border-0">
											<b-card-header header-tag="header" class="p-1 border-0" role="tab" v-b-toggle.accordion-domain>
												
												<div class="row">
													<div class="col-8">
														<b-button variant="default"><h5 class="m-0" style="font-weight: 400;">{{ $t('Domains') }}</h5></b-button>
													</div>
													<div class="col-4 my-auto text-right">
														<span class="btn btn-icons">
															<i class="fa fa-angle-down" v-if="Accordion.Domain"></i>
															<i class="fa fa-angle-up" v-else></i>
														</span>
													</div>
												</div>
												
											</b-card-header>
											<b-collapse :visible="Stepper == 'Domain'" id="accordion-domain" accordion="analytics-accordion" role="tabpanel" v-model="Accordion.Domain">
												<b-card-body>
													<b-card-text>
														<div class="row" v-if="!Instructions">
															<div class="col-12 mb-2">
																<label class="d-block m-0  fs-15">{{ $t('AddYourOwnDomain') }}</label>
																
															</div>
															
															<div class="col-12">
																<input type="url" class="form-control" :disabled="AgencyArray.domain_status != ''" placeholder="https://www.ohmylead.com/" v-model="AgencyArray.domain_name" required />
																<small class="fs-12" v-html="$t('AddYourOwnDomainDesc')"></small>
															</div>

															<div class="col-12 mb-2 mt-3">
																<label class="d-block m-0  fs-15">{{ $t('AddYourOwnDomainMobile') }}</label>
																
															</div>
															
															<div class="col-12">
																<input type="url" class="form-control" :disabled="AgencyArray.domain_status != ''" placeholder="https://m.ohmylead.com/" v-model="AgencyArray.domain_name_mobile" required />
																<small class="fs-12" v-html="$t('AddYourOwnDomainDescMobile')"></small>
															</div>
															<div class="col-12 text-right mt-3">
																<button class="oml-btn" style=" width: 12em; " @click="SaveDomain" :disabled="AgencyArray.domain_status != ''">
																	{{ $t('SaveDomain') }}
																</button>
															</div>
															
															<div class="col-md-12">
																<div class="alert alert-warning mt-3 mb-0" style="font-size: 14px;padding-right: 12px;" v-if="AgencyArray.domain_status != ''">
																	<i class="fa fa-info-circle" style="position: absolute;left: 5px;font-size: 38px;top: 15px;"></i>
																	<div style="padding-left: 22px;">
																		<span style="font-size: 12px;">
																			{{ $t('ConfigWLDesc') }}
																		</span>
																	</div>
																</div>
															</div>
															
															<!--div class="col-12" v-if="!AgencyArray.domain_exist && CheckStat">
																<div class="row text-danger">
																	<div class="col-1 my-auto">
																		<i class="fa fa-exclamation-triangle" style="font-size: 30px;"></i>
																	</div>
																	<div class="col-11 pl-0">
																		<small>
																			There is an issue with your domaine name, please check 
																			<span style="text-decoration: underline; cursor: pointer;" @click="Instructions = !Instructions">detail instructions</span> 
																			to see how you can configure you domaine name
																		</small>
																	</div>
																</div>
															</div-->
														</div>
														<div class="row" v-if="Instructions"><!-- v-if="DomainAgency" -->
															<div class="col-12 form-group">
																<div class="alert alert-info row m-0">
																	<div class="col-2 m-auto">
																		<!--i class="fa fa-globe" style="font-size: 65px;"></i-->
																		<img src="img/White-Label/Icon-metro-earth.svg" style="width: 60px;" />
																	</div>
																	<div class="col-10 pl-0">
																		<b>{{ $t('OneLastStep') }} !</b>
																		<p class="m-0" style="font-size: 12px;" v-html="$t('LastStepDescription')">
																			<!-- In order for your custom to work you’ll need to log in to where you bought your domain and add <b>A record</b> that points www at <b>104.248.83.126</b> if you are not sur how to do this we have detailed instructions for most domain registers -->
																		</p>
																	</div>
																</div>
															</div>
															
															<div class="col-12 text-right">
																<button class="btn btn-upgrade btn-lg" style=" width: 12em; " @click="NextStep('Logo')">{{ $t('IveDoneThisStep') }}</button>
															</div>
														</div>
														
													</b-card-text>
												</b-card-body>
											</b-collapse>
										</b-card>
									</div>
									
								</div>
							</li>
							<li>
								<div :class="['cbp_tmicon', StepCheck.Logo || Accordion.Logo ? 'cbp_tmicon-check' : '']">
									<i class="fa fa-check" v-if="StepCheck.Logo"></i>
									<span v-else>2</span>
								</div>
								<div class="cbp_tmlabel fadeInRight animated">
									
									<div class="row information-agency mt-3">
										<b-card no-body class="col-12 border-0">
											<b-card-header header-tag="header" class="p-1 border-0" role="tab" v-b-toggle.accordion-logo>
												
												<div class="row">
													<div class="col-8">
														<b-button variant="default"><h5 class="m-0" style="font-weight: 400;">{{ $t('CustomizeLogo') }}</h5></b-button>
													</div>
													<div class="col-4 my-auto text-right">
														<span class="btn btn-icons">
															<i class="fa fa-angle-down" v-if="Accordion.Logo"></i>
															<i class="fa fa-angle-up" v-else></i>
														</span>
													</div>
												</div>
												
											</b-card-header>
											<b-collapse :visible="Stepper == 'Logo'" id="accordion-logo" accordion="analytics-accordion" role="tabpanel" v-model="Accordion.Logo">
												<b-card-body>
													<b-card-text>
														
														<div class="form-group row">
															<div class="col-12">
																<label class="d-block fs-15">{{ $t('Logo') }}</label>
																<input type="file" class="form-control" id="image_input" accept="image/*" @change="PreviewImage" required="required"/>
																<small class="fs-12">{{ $t('WLLogoDesc') }}</small>
															</div>
														</div>
														<div class="form-group row" v-if="AgencyArray.logo != ''"><!-- && AgencyArray.image_data != '' -->
															<div class="col-12">
																<label class="d-block">{{ $t('CurrentLogo') }}</label>
																<div class="row" v-if="AgencyArray.domain_status != '' && AgencyArray.image_data == ''">
																	<div class="col-2 text-center">
																		<img :src="AgencyArray.logo" style="width: 50px;" :alt="AgencyArray.logo" />
																	</div>
																	<div class="col-8 my-auto">
																		<b>{{ $t('CurrentLogo') }}</b>
																	</div>
																	<div class="col-2 text-right my-auto">
																		<a @click="InitImage" class="btn btn-light"><i class="fa fa-trash text-danger"></i></a>
																	</div>
																</div>
																<div class="row" v-else>
																	<div class="col-2 text-center">
																		<!--img src="/img/Integration/logo.png" style="width: 50px;" /-->
																		<img :src="AgencyArray.image_data" style="width: 50px;" :alt="AgencyArray.logo" />
																	</div>
																	<div class="col-8 my-auto">
																		<b>{{ AgencyArray.logo }}</b>
																		<div><small>{{ AgencyArray.image_size | bytesToSize }}</small></div>
																	</div>
																	<div class="col-2 text-right my-auto">
																		<a @click="InitImage" class="btn btn-light"><i class="fa fa-trash text-danger"></i></a>
																	</div>
																</div>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-12">
																<label class="d-block fs-15">{{ $t('Favicon') }}</label>
																<small style="display:none">{{ $t('WLEmailDesc') }}</small>
																<input type="file" class="form-control" id="favicon_input" accept="image/*" @change="PreviewFavicon" required="required"/>
															</div>
														</div>
														<div class="form-group row" v-if="AgencyArray.favicon != ''"><!-- && AgencyArray.image_data != '' -->
															<div class="col-12">
																<label>{{ $t('CurrentFavicon') }}</label>
																<div class="row" v-if="AgencyArray.domain_status != '' && AgencyArray.favicon_data == ''">
																	<div class="col-2 text-center">
																		<img :src="AgencyArray.favicon" style="width: 50px;" :alt="AgencyArray.favicon" />
																	</div>
																	<div class="col-8 my-auto">
																		<b>{{ $t('CurrentFavicon') }}</b>
																	</div>
																	<div class="col-2 text-right my-auto">
																		<a @click="InitFavicon" class="btn btn-light"><i class="fa fa-trash text-danger"></i></a>
																	</div>
																</div>
																<div class="row" v-else>
																	<div class="col-2 text-center">
																		<!--img src="/img/Integration/logo.png" style="width: 50px;" /-->
																		<img :src="AgencyArray.favicon_data" style="width: 50px;" :alt="AgencyArray.favicon" />
																	</div>
																	<div class="col-8 my-auto">
																		<b>{{ AgencyArray.favicon }}</b>
																		<div><small>{{ AgencyArray.favicon_size | bytesToSize }}</small></div>
																	</div>
																	<div class="col-2 text-right my-auto">
																		<a @click="InitFavicon" class="btn btn-light"><i class="fa fa-trash text-danger"></i></a>
																	</div>
																</div>
															</div>
														</div>
														
														<hr>
														<div class="row">
															<div class="col-12 text-right">
																<button class="oml-btn mr-2" style=" height: 3em; width: 8em; " @click="NextStep('Domain')">{{ $t('Previous') }}</button>
																<button class="oml-btn" style=" height: 3em; width: 8em; " @click="NextStep('Finish')">{{ $t('Next') }}</button>
															</div>
														</div>
														
													</b-card-text>
												</b-card-body>
											</b-collapse>
										</b-card>
									</div>
									
									
								</div>
							</li>
							<li>
								<div :class="['cbp_tmicon', StepCheck.Finish || Accordion.Finish ? 'cbp_tmicon-check' : '']">
									<i class="fa fa-check" v-if="StepCheck.Finish"></i>
									<span v-else>3</span>
								</div>
								<div class="cbp_tmlabel fadeInRight animated">
									
									
									<div class="row information-agency mt-3 mb-5"><!-- information-agency -->
										<b-card no-body class="col-12 border-0">
											<b-card-header header-tag="header" class="p-1 border-0" role="tab" v-b-toggle.accordion-info>
												
												<div class="row">
													<div class="col-8">
														<b-button variant="default"><h5 class="m-0" style="font-weight: 400;">{{ $t('CustomizeEmail') }}</h5></b-button>
													</div>
													<div class="col-4 my-auto text-right">
														<span class="btn btn-icons">
															<i class="fa fa-angle-down" v-if="Accordion.Finish"></i>
															<i class="fa fa-angle-up" v-else></i>
														</span>
													</div>
												</div>
												
											</b-card-header>
											<b-collapse :visible="Stepper == 'Finish'" id="accordion-info" accordion="analytics-accordion" role="tabpanel" v-model="Accordion.Finish">
												<b-card-body>
													<div class="form-group row">
														<div class="col-12">
															<label class="d-block fs-15">{{ $t('CompanyName') }}</label>
															<input type="text" class="form-control " :placeholder="$t('CompanyName')" v-model="AgencyArray.company_name" required />
														</div>
													</div>
													<div class="form-group row">
														<div class="col-12">
															<label class="d-block fs-15">{{ $t('FromName') }}</label>
															<input type="text" class="form-control " :placeholder="$t('FromName')" v-model="AgencyArray.mail_from_name" required />
														</div>
													</div>
													<div class="form-group row">
														<div class="col-12">
															<label class="d-block fs-15">{{ $t('FromEmail') }}</label>
															<small style="display:none">{{ $t('WLEmailDesc') }}</small>
															<input type="email" class="form-control " placeholder="contact@ohmylead.com" v-model="AgencyArray.mail_from_address" required />
														</div>
													</div>
													
													<div class="row mt-3">
														<div class="col-12 text-right">
															<!--button class="btn btn-upgrade btn-lg" style=" width: 12em; height: 45px; " v-if="DomainAgency" @click="EditInfo">Save</button-->
															<button class="oml-btn" style=" width: 12em; height: 45px; " @click="SaveCompany">{{ $t('SaveChanges') }}</button>
														</div>
													</div>
												</b-card-body>
											</b-collapse>
										</b-card>
									</div>
									
									
								</div>
							</li>
						</ul>
					</div>
					
				</div>
				
			</div>
		</div>
		
		<b-modal id="modal-whitelabel" centered title="BootstrapVue" hide-header hide-footer>
			<div>
				<i class="fa fa-close float-right c-pointer" @click="$bvModal.hide('modal-whitelabel')"></i>
				<div class="text-center" style="padding: 20px 40px;" v-if="AgencyArray.domain_exist">
					<img src="img/White-Label/Check.svg" style="margin-bottom: 12px;" />
					<h5>{{ $t('H5ModalWL') }}</h5>
					<small class="my-4 d-block">{{ $t('SmallModalWL') }}</small>
					<div >
						<button class="btn btn-success" style=" height: 50px; text-transform: uppercase;padding: 10px 25px; " @click="TakeALook">
							<img src="img/White-Label/Icon-eye.svg" style="width: 22px;" /> <span style="font-size: 12px;">{{ $t('TakeALookWL') }}</span>
						</button>
					</div>
				</div>
				<div class="text-center mb-2" style="padding: 20px 40px;" v-else>
					<img src="img/White-Label/info-icon.svg" style="margin-bottom: 12px; width: 8em;" />
					<h5>{{ $t('DomainVerfWL') }}</h5>
					<small class="my-4 d-block">{{ $t('DoNotPanicWL') }}</small>
				</div>
			</div>
		</b-modal>
		
    </div>
</template>

<script>
	export default {
		name: 'team',
		data() {
			return {
				Stepper		  : 'Domain',
				CheckStat	  : false,
				globalLoading : false,
				Loading		  : false,
				AgencyArray	  : {
					domain_id	: '',
					domain_name	: '',
					domain_name_mobile	: '',
					description	: '',
					company_name: '',
					company_page: '',
					mail_from_name	 : '',
					mail_from_address: '',
					
					logo		: '',
					image_data	: '',
					image_size	: '',
					
					favicon		: '',
					favicon_data: '',
					favicon_size: '',
					
					domain_exist: false,
					domain_status: '',
				},
				DomainAgency	: false,
				
				Instructions	: false,
				
				Accordion		: { Domain: false, Logo: false, Finish: false },
				StepCheck		: { Domain: false, Logo: false, Finish: false },
				accordionLogo : false
				
			}
		},
		methods: {
			Reconfig() {
				
				let that = this
				
				let domain_status = that.AgencyArray.domain_status
				that.AgencyArray.domain_status	= ''
				
				let url   = '/domain/reconfig'
				let method= 'POST'
				
				let data  = { domain_name: that.AgencyArray.domain_name, domain_name_mobile: that.AgencyArray.domain_name_mobile }
				that.globalLoading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => { // response
					that.AgencyArray.domain_status	= domain_status
				}).catch((  ) => { // {response}
					
				})
				
			},
			NextStep(step) {
				let that 	 = this
				that.Stepper = ''
				
				if( step == 'Finish' ) {
					if(this.AgencyArray.favicon_data){
						that.SaveLogo()
						that.accordionLogo = true

						Object.keys( that.Accordion ).forEach( function(item) {
							that.Accordion[item] = false
						} )
						
						setTimeout( function() {
							that.Stepper = step
							that.Accordion[step] = true
						}, 400 )
					}else{
						that.accordionLogo = false
						that.notifyMe(this.$t('mustChooseLogoFavicon'), 'warn')
					}
					
				} else if( step == 'Logo' ) {
					that.Reconfig()

					Object.keys( that.Accordion ).forEach( function(item) {
						that.Accordion[item] = false
					} )
					
					setTimeout( function() {
						that.Stepper = step
						that.Accordion[step] = true
					}, 400 )
					
				}
				
				
				
			},
			
			TakeALook() {
				let that = this
				that.$bvModal.hide('modal-whitelabel')
				window.open( that.AgencyArray.domain_name, '_blank' )
				// location.reload()
			},
			
			SaveCompany() {
				let that = this
				
				let url   	= 'domain/finish'
				let method	= 'POST'
				let data  	= {
					description			: '',
					company_name		: that.AgencyArray.company_name,
					company_page		: that.AgencyArray.company_page,
					mail_from_name		: that.AgencyArray.mail_from_name,
					mail_from_address 	: that.AgencyArray.mail_from_address,
				}
				
				if(that.AgencyArray.mail_from_address == '') {
					that.notifyMe(this.$t('emailFormRequired'), 'warn')
					return false
				}
				that.CheckDomain()
				
				that.Instructions = false
				that.Loading 	  = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					if( response.data.success ) {
						// that.Stepper = 'Finish'
						that.DomainAgency = true
						// that.CheckDomain()
						// that.notifyMe(response.data.message, 'success')
						that.$bvModal.show('modal-whitelabel')
						that.StepCheck.Finish = true
						that.Accordion.Finish = true
					} else {
						that.notifyMe(response.data.message, 'warn')
					}
					that.Loading = false
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.Loading = false
				})
			},
			
			SaveLogo() {
				let that = this
				
				let url   	= 'domain/upload'
				let method	= 'POST'
				let data  	= {
					logo		: that.AgencyArray.logo,
					image_data	: that.AgencyArray.image_data,
					favicon		: that.AgencyArray.favicon,
					favicon_data: that.AgencyArray.favicon_data
				}
				
				that.Loading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					if( response.data.success ) {
						that.notifyMe(response.data.message, 'success')
						that.StepCheck.Logo = true
						// that.Stepper = 'Finish'
						// that.Accordion.Finish = true
					} else {
						that.notifyMe(response.data.message, 'warn')
					}
					that.Loading = false
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.Loading = false
				})
			},
			
			SaveDomain() {
				let that = this
				
				if( that.AgencyArray.domain_name == '' ) {
					that.notifyMe(this.$t('domainNameRequired'), 'warn')
					return false
				}
				
				let url   	= 'domain/create'
				let method	= 'POST'
				let data  	= { domain_name: that.AgencyArray.domain_name,domain_name_mobile: that.AgencyArray.domain_name_mobile  }
				that.Loading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					that.CheckStat = true
					if( response.data.success ) {
						that.AgencyArray.domain_name  	= response.data.domain.domain_name
						that.AgencyArray.domain_name_mobile  	= response.data.domain.domain_name_mobile
						that.Instructions = true
						that.notifyMe(response.data.message, 'success')
						that.StepCheck.Domain = true
						that.Accordion.Domain = true
					} else {
						console.log(response.data)
						console.log(response.data.message)
						if( response.data.message != '' ) {
							that.notifyMe(response.data.message, 'warn')
						} else {
							let varKey  = Object.keys( response.data.errors ) //, valuesObj = Object.values( response.data.message );
							var message = ''
							let objMsg  = response.data.errors
							varKey.forEach( function(item) {
								message += '- ' + objMsg[item] + "<br>"
							} )
							that.notifyMe(message, 'warn')
						}
					}
					that.Loading = false
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.Loading = false
				})
			},
			
			
			/* SubmitAction() {
				let that = this
				
				if(that.$store.state.user.plan_name != 'Agency') {
					that.notifyMe('Agency Plan required !', 'warn')
					return false
				}
				
				if( that.DomainAgency ) {
					that.EditInfo()
				} else {
					that.SaveChanges()
				}
			}, */
			
			InitImage() {
				let that = this
				that.AgencyArray.logo 		= ''
				that.AgencyArray.image_data = ''
				that.AgencyArray.image_size = ''
				
				document.getElementById('image_input').value = ''
			},
			InitFavicon() {
				let that = this
				that.AgencyArray.favicon 	  = ''
				that.AgencyArray.favicon_data = ''
				that.AgencyArray.favicon_size = ''
				
				document.getElementById('favicon_input').value = ''
			},
			
			PreviewImage(e) {
				let that = this
				var files = e.target.files, fileImport = files[0];
				
				that.AgencyArray.logo 		= fileImport.name
				that.AgencyArray.image_size	= fileImport.size
				
				var reader = new FileReader();
				reader.onload = function(e) {
					that.AgencyArray.image_data = e.target.result
				};
				reader.readAsDataURL(fileImport)
				// reader.readAsText(fileImport);
				// reader.readAsBinaryString(fileImport);
				// reader.readAsArrayBuffer(fileImport);
			},
			
			PreviewFavicon(e) {
				let that = this
				var files = e.target.files, fileImport = files[0];
				
				that.AgencyArray.favicon 		= fileImport.name
				that.AgencyArray.favicon_size	= fileImport.size
				
				var reader = new FileReader();
				reader.onload = function(e) {
					that.AgencyArray.favicon_data = e.target.result
				};
				reader.readAsDataURL(fileImport)
				// reader.readAsText(fileImport);
				// reader.readAsBinaryString(fileImport);
				// reader.readAsArrayBuffer(fileImport);
			},
			
			DeleteDomain() {
				let that = this
				
				that.Loading = true
				
				let url   = '/domain/delete'
				let method= 'POST'
				that.AgencyArray.domain_id = that.$store.state.user.domain_id
				let data  = { domain_id: that.AgencyArray.domain_id }
				that.Loading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					that.DomainAgency = false
					that.AgencyArray.domain_name  	= ''
					that.AgencyArray.domain_name_mobile  	= ''
					that.AgencyArray.logo			= ''
					that.AgencyArray.description	= ''
					that.AgencyArray.company_name	= ''
					that.AgencyArray.company_page		= ''
					that.AgencyArray.mail_from_name	 	= ''
					that.AgencyArray.mail_from_address	= ''
					
					that.AgencyArray.image_data		= ''
					that.AgencyArray.image_size		= ''
					
					that.AgencyArray.favicon	 	= ''
					that.AgencyArray.favicon_data	= ''
					that.AgencyArray.favicon_size	= ''
					
					that.AgencyArray.domain_exist	= false
					that.AgencyArray.domain_status	= ''
					
					that.Loading = false
					that.notifyMe(response.data.message, 'success')
					
					that.StepCheck.Domain 	= false
					that.StepCheck.Logo 	= false
					that.StepCheck.Finish 	= false
					that.Accordion.Domain 	= true
					
					// location.reload()
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.Loading = false
				})
				
			},
			
			GetDomain() {
				let that = this
				
				let url   = '/domain/get'
				let method= 'POST'
				// domain_id
				let data  = { domain_id: that.AgencyArray.domain_id }
				that.globalLoading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					let feedback = response.data.domain
					if( feedback != null && typeof( feedback ) != 'undefined' ) {
						that.DomainAgency = true
						that.AgencyArray.domain_name  	= feedback.domain_name
						that.AgencyArray.logo			= feedback.logo != null ? feedback.logo : ''
						// that.AgencyArray.image_data		= feedback.logo
						that.AgencyArray.description	= feedback.description
						that.AgencyArray.company_name	= feedback.company_name
						that.AgencyArray.company_page		= feedback.company_page
						that.AgencyArray.mail_from_name	 	= feedback.mail_from_name
						that.AgencyArray.mail_from_address	= feedback.mail_from_address
						
						that.AgencyArray.favicon	 	= feedback.favicon != null ? feedback.favicon : ''
						// that.AgencyArray.favicon_data	= ''
						that.CheckDomain()
					} else {
						that.Accordion.Domain = true
					}
					that.globalLoading = false
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.globalLoading = false
				})
				
			},
			
			/* SaveChanges() {
				let that = this
				
				let url   = '/createdomain'
				let method= 'POST'
				let data  = that.AgencyArray
				that.Loading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					
					that.notifyMe(response.data.message, 'success')
					that.Loading = false
					
					let feedback = response.data.domain
					if( feedback != null && typeof( feedback ) != 'undefined' ) {
						that.DomainAgency = true
						that.AgencyArray.domain_id  	= feedback.id
						
						that.$store.state.user.domain_id= feedback.id
						
						that.AgencyArray.domain_name  	= feedback.domain_name
						that.AgencyArray.logo			= feedback.logo != null ? feedback.logo : ''
						that.AgencyArray.image_data		= ''
						that.AgencyArray.description	= feedback.description
						that.AgencyArray.company_name	= feedback.company_name
						that.AgencyArray.company_page		= feedback.company_page
						that.AgencyArray.mail_from_name	 	= feedback.mail_from_name
						that.AgencyArray.mail_from_address	= feedback.mail_from_address
						
						
						that.AgencyArray.favicon			= feedback.favicon != null ? feedback.favicon : ''
						that.AgencyArray.favicon_data		= ''
						
						localStorage.setItem('oml-user' , JSON.stringify( that.$store.state.user ) )
					}
					
				}).catch(( {response} ) => {
					if( response.data.message != '' ) {
						
						let varKey  = Object.keys( response.data.errors ) //, valuesObj = Object.values( response.data.message );
						var message = ''
						let objMsg  = response.data.errors
						varKey.forEach( function(item) {
							message += '- ' + objMsg[item] + "<br>"
						} )
						that.notifyMe(message, 'warn')
						// that.notifyMe(response.data.message, 'warn')
						
					}
					that.Loading = false
				})
				
			}, */
			
			/* EditInfo() {
				let that = this
				
				let url   = '/editdomain'
				let method= 'POST'
				let data  = that.AgencyArray
				that.Loading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					that.notifyMe(response.data.message, 'success')
					that.Loading = false
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.Loading = false
				})
			}, */
			EditDomain() {
				
			},
			CheckDomain() {
				let that = this
				
				let url   = '/domain/check'
				let method= 'POST'
				// let hostname = window.location.hostname
				// let data  = { domain_id: that.AgencyArray.domain_id, hostname: hostname }
				let data  = { domain_name: that.AgencyArray.domain_name }
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.AgencyArray.domain_exist = response.data.exist
					
					that.AgencyArray.domain_status = response.data.exist ? 'Success' : 'Pending'
					that.Accordion.Domain = false
					that.StepCheck.Domain = true
					that.StepCheck.Logo = true
					that.StepCheck.Finish = true
					
					// domain: "https://192.168.1.7:8086/"
					// exist: false
					// found: false
					// message: "check Domain"
					// success: true
					
				}).catch((  ) => { // {response}
					// that.notifyMe(response.data.message, 'warn')
					// that.Loading = false
				})
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this
			that.AgencyArray.domain_id = that.$store.state.user.domain_id
			that.GetDomain()
			
			that.$store.state.selectedPage = 'whitelabel'
			
		},
		
		filters: {
			bytesToSize(bytes) {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes == 0) return '0 Byte';
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
				return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
			}
		},
		
	}
</script>

<style lang="scss">
	
	.information-agency {
		box-shadow: 1px 1px 10px #eee;
		padding: 12px 0px;
		border: 1px solid #eee;
		border-radius: 4px;
		
		label {
			font-weight: 600;
		}
	}
	
	.table-grid {
		thead th {
			font-weight: normal !important;
			font-size: 14px;
		}
	}
	
	.btn-icons {
		font-size: 18px;
		border-radius: 100%;
		background-color: #e5f1ff;
		width: 42px;
		height: 42px;
		color: #007bff;
	}
	
	
	
	
	
	
	
	
	
	
	
	
	
	.cbp_tmtimeline {
		padding: 0;
		list-style: none;
		position: relative;
	}
	
	.cbp_tmtimeline:before {
		content: '';
		position: absolute;
		top: 10%;
		bottom: 0;
		width: 2px;
		background: #ccc;
		left: 0px;
		height: 100%;
	}
	
	.cbp_tmtimeline li:last-child:before {
		content: initial;
	}
	
	.cbp_tmtimeline > li .cbp_tmtime {
		display: block;
		width: 25%;
		padding-right: 100px;
		position: absolute;
	}
	
	.cbp_tmtimeline > li .cbp_tmtime span {
		display: block;
		text-align: right;
	}
	
	.cbp_tmtimeline > li .cbp_tmtime span:first-child {
		font-size: 0.9em;
		color: #bdd0db;
	}
	
	.cbp_tmtimeline > li .cbp_tmtime span:last-child {
		font-size: 2.9em;
		color: #3594cb;
	}
	
	.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
		color: #6cbfee;
	}
	
	.cbp_tmtimeline > li .cbp_tmlabel {
		margin: 0 0 0px 4em;
	}
	
	.cbp_tmtimeline > li {
		.cbp_tmicon {
			width: 50px;
			height: 50px;
			top: 18%;
			font-size: 1.4em;
			line-height: 48px;
			position: absolute;
			
			border-radius: 50%;
			text-align: center;
			left: 1px;
			margin: 0 0 0 -25px;
			
			color: #525252;
			background: #fff;
			border: 2px solid #ccc;
		}
		.cbp_tmicon-check {
			color: #ffffff;
			background: #00BBF6;
			border: 2px solid #00BBF6;
		}
	}

	.cbp_tmtimeline li {
		position: relative;
	}
	
	
	.whiteLabel-table{
		margin-top:0px !important;
		padding:0 17px;
		.whiteLabel-thead{
			border-bottom:1px solid #e7eaf3;
			padding:10px 0;
			
			.whiteLabel-th{
				small{
					color: #8c8c8c !important;
					font-weight: normal !important;
					font-size: 14px !important;
				}
			}
			
			
		}
		
		.whiteLabel-tr{
			
			&:hover{
				box-shadow: 0px 0px 30px -14px #000000;
				-webkit-box-shadow: 0px 0px 30px -14px #000000;
			}

			&:nth-of-type(2n+1) {
				background-color: #F8FDFF;
			}
			.whiteLabel-td{
				padding-top:5px;
				padding-bottom:5px;
			}
		}
	}
	
</style>
