<template>
	<div class="lead-status">
		
		<div class="event_processing" v-show="loadstatus">
			<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
		</div>
		
		<div class="row">
			<div class="col-12">
				<h6 class="oml-h6Title">{{ $t('LeadStatus') }}
					<i @click="addNewStatus" class="fa fa-plus-circle cursor-pointer" style="color:#377cff;font-size: 17px;margin-left: 7px;"></i>
				</h6>
				
				<div class="p-desc mt-3">
					<p v-html="$t('desc_leadStatus')"></p>
				</div>
			</div>
		</div>
		
		<div class="col-md-12 mx-auto mb-5" v-if="!$store.state.active || ($store.state.active && 'list status' in $store.state.permissions)">
			
			<div class="row oml-statusHead-tr">
				<div class="col-md-4 oml-status-th">
					<b></b>
					{{ $t('StatusLabel') }}
				</div>
				<div class="col-md-2 oml-status-th text-center">

						{{ $t('Show') }}
						<i class="fa fa-question-circle text-primary" id="popover-iShowStatus"></i>
						<b-popover target="popover-iShowStatus" triggers="hover" placement="auto">
							{{ $t('iShowStatus') }}
						</b-popover>

				</div>
				<div class="col-md-2 oml-status-th text-center">

						{{ $t('Default') }}
						<i class="fa fa-question-circle text-primary" id="popover-iDefault"></i>
						<b-popover target="popover-iDefault" triggers="hover" placement="auto">
							{{ $t('iDefault') }}
						</b-popover>

				</div>
				<div class="col-md-2 oml-status-th text-center px-0">

						{{ $t('IsQualified') }}
						<i class="fa fa-question-circle text-primary" id="popover-iIsQualified"></i>
						<b-popover target="popover-iIsQualified" triggers="hover" placement="auto">
							{{ $t('iIsQualified') }}
						</b-popover>

				</div>
				<div class="col-md-2 oml-status-th text-center">
					{{ $t('Action') }}
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 pb-0" style="padding: 10px 30px;">
					<vue-draggable v-model="myArrayStatus" draggable=".item">
						<div v-for="(element, key) in myArrayStatus" :key="`key-${key}`" class="row item mb-1"><!-- element.id -->
						<div class="row oml-statusBody-tr">
							<div class="col-md-3 border pt-2 pb-2 rounded move-draggable-md oml-status-td"><!-- <i class="fa fa-ellipsis-v"></i> -->
								<i class="fa fa-arrows mr-1"></i>
								<span v-if="element.newField != 'edit'">
									{{ element.state }}
								</span>
								<span v-else>
									<input type="text" :class="['inputCustomStatus', element.empty ? 'field-required' : '']" v-model="element.state" />
									<!--small>Special characters &amp; Whitespace  are not allowed</small-->
									<small class="float-left ml-3 pl-1" v-html="$t('SpecialCharacters')"></small>
								</span>
							</div>
							<div class="col-md-1 my-auto text-center oml-status-td">
								<color-picker-md :color="element.color" v-model="element.color" @onChange="SaveStatus = true" />
							</div>
							<div class="col-md-2 text-center my-auto pl-4 oml-status-td">
								<div class="custom-control custom-switch b-custom-control-lg" @click="SaveStatus = true"> <!-- v-if="element.id != SelectedStatus" -->
									<input type="checkbox" class="custom-control-input" :id="`statusKey-${element.id}`" :value="element.status" v-model="element.status">
									<label class="custom-control-label" :for="`statusKey-${element.id}`"></label>
								</div>
							</div>
							<div class="col-md-2 text-center my-auto oml-status-td">
								
								<!--div class="custom-control custom-radio" v-if="element.newField != 'edit'">
									<input type="radio" class="custom-control-input" :id="`defaultKey-${element.id}`" v-model="SelectedStatus" @click="element.status = true, SaveStatus = true" :value="element.id">
									<label class="custom-control-label" :for="`defaultKey-${element.id}`"></label>
								</div-->
								
								<div class="custom-control custom-checkbox" v-if="element.newField != 'edit'">
									<input type="checkbox" class="custom-control-input" :id="`defaultKey-${element.id}`" v-model="element.defaut" @click="SaveStatus = true">
									<label class="custom-control-label" :for="`defaultKey-${element.id}`"></label>
								</div>
								
							</div>
							<div class="col-md-2 text-center my-auto oml-status-td">
								
								<div class="custom-control custom-checkbox" v-if="element.state != 'Pending' && element.state != 'En attente'">
									<input type="checkbox" class="custom-control-input" :id="`is_qualified-${element.id}`" v-model="element.is_qualified" @click="SaveStatus = true">
									<label class="custom-control-label" :for="`is_qualified-${element.id}`"></label>
								</div>
								
							</div>
							<div class="col-md-2 text-center my-auto oml-status-td">
								
								<!--button v-if="element.newField == 'edit'" type="button" @click="saveStatus(element)" class="btn btn-outline-primary btn-sm mr-1 rounded-circle"><i class="fa fa-floppy-o"></i></button-->
								<!--button v-if="element.newField" type="button" @click="myArrayStatus.splice(key, 1)" class="btn btn-outline-danger btn-sm ml-1 rounded-circle">
									<i class="fa fa-trash-o"></i>
								</button-->
								<!--span v-if="element.status != 'new'">{{ element.state }}</span-->
								<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" right no-caret class="p-0" v-if="element.state != 'Pending'">
									<template v-slot:button-content  >
										<i style="font-size: 18px;" class="fa fa-ellipsis-v dropdown-icon" v-if="!$store.state.active || ($store.state.active && ('delete status' in $store.state.permissions || 'edit status' in $store.state.permissions))"></i>
									</template>
									<div v-if="!$store.state.active || ($store.state.active && 'delete status' in $store.state.permissions)">
										<b-dropdown-item @click="DeleteStatus(element.id, key)" v-if="element.removable && (!$store.state.active || ($store.state.active && 'delete status' in $store.state.permissions))">
											<i class="fa fa-trash pr-1"></i> {{ $t('DeleteStatus') }}
										</b-dropdown-item>
									</div>
									
									<b-dropdown-item @click="EditStatus(element, key)" v-if="!$store.state.active || ($store.state.active && 'edit status' in $store.state.permissions)">
										<i class="fa fa-edit pr-1"></i> {{ $t('EditStatus') }}
									</b-dropdown-item>
								</b-dropdown>
							</div>
							</div>
							
							<!-- { id: entry.id, state: entry.state, order: entry.order, status: entry.status, defaut: entry.defaut, color: entry.color } -->
						</div>
					</vue-draggable>
					<div class="row">
						<div class="col-md-12 my-auto pt-3 pb-0 addCustomStatus" v-if="!$store.state.active || ($store.state.active && 'add status' in $store.state.permissions)">
							<span id="AddCustomStatus" @click="addNewStatus"><i class="fa fa-plus"></i> {{ $t('AddCustomStatus') }}</span>
						</div>
					</div>
				</div>
			</div>
			
			<div class="row">
				<div class="col-md-12 text-right">
					
					<div class="form-group" v-if="SaveStatus">
						<button type="submit" class="oml-btn min-w-200" @click="saveStatusChnages">
							{{ $t('Save') }} <i v-if="loadingData" class="fa fa-spinner fa-spin"></i>
						</button>
					</div>
					
				</div>
			</div>
		</div>
		<div v-if="$store.state.active && !Object.keys($store.state.permissions).includes('list status')">
			<h5 class="sync-text">
				<img src="/img/svg/info-circle.svg" />
				<span style="text-decoration:none"> {{ $t('Noaccess') }}</span>
			</h5>
		</div>
    </div>
</template>

<script>
	import vueDraggable from 'vuedraggable'
	import colorPickerMd from '@/plugins/md/colorPicker.vue';
	
	export default {
		name: 'LeadStatus',
		components: {
			vueDraggable,
			colorPickerMd
		},
		data() {
			return {
				// SelectedStatus	: '',
				myArrayStatus	:[],
				loadingData		: false,
				loadstatus		: false,
				SaveStatus		: false
			}
		},
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					text	: message,
					type	: variant,
					// title	: 'Important message',
					// duration	: 500000,
					// speed	: 1000
				});
			},
			EditStatus (status) {
				console.log(status)
				let that = this
				status.newField = 'edit'
				that.SaveStatus = true
			},
			DeleteStatus(element, key) {
				let that = this
				
				let url   = '/leads/delete_status/' + element
				let method= 'delete'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((/* response */) => {
					that.loadstatus = false
					that.notifyMe(this.$t('ChangesSaved'), 'success')
					that.GetStatusInfo()
					that.myArrayStatus.splice(key, 1)
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadstatus = false
				})
				
			},
			
			addNewStatus () {
				let that = this
				that.SaveStatus = true
				that.myArrayStatus.push({
					id		: that.myArrayStatus.length + 1,
					state	: '',
					order	: that.myArrayStatus.length + 1,
					status	: 'new',
					defaut	: 0,
					color	: '#35d5bc',
					empty	: false,
					newField: 'edit',
					removable: 0,
					is_qualified: 0
				})
				
				const el = document.getElementById('AddCustomStatus');
				el.scrollIntoView({behavior: "smooth"});
			},
			
			saveStatusChnages () {
				let that = this, empty = false
				
				that.myArrayStatus.forEach( function(element, key) {
					element.defaut = element.defaut == true ? 1 : element.defaut == false ? 0 : element.defaut
					if( element.status == 'new' ) {
						if( element.state == '' ) {
							empty = true
						} else {
							element.newField = true
						}
					} else {
						element.newField = false
					}
					that.myArrayStatus[key].is_qualified = that.myArrayStatus[key].is_qualified ? 1 : 0
				} )
				if( empty ) {
					that.notifyMe('Status cannot be empty !', 'warn')
					return false
				}
				that.loadingData = true
				
				let url   = 'leads/save_status_changes'
				let method= 'POST'
				let data  = {myArrayStatus:that.myArrayStatus, provider : "add status", permissionid : 20} // defaultStatusSelected:that.SelectedStatus, 
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then(( response ) => {
					
					if( response.data.success ) {
						
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						that.myArrayStatus.forEach( function(element) {
							element.newField = false
							element.empty 	 = false
						} )
						that.GetStatusInfo()
						
					} else {
						that.notifyMe(response.data.message, 'warn')
					}
					that.loadingData = false
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingData = false
					that.GetStatusInfo()
				})
				
			},
			
			saveStatus (element) {
				let that = this
				// var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
				// var format = /[.*+?^${}()|[\]\\]/g;
				var format = /[ !@#%&_,":'=.*+?^${}()|[\]\\]/g;
				if( element.state != '' && !format.test(element.state) ) {
					element.newField = true
					element.empty = false
				} else {
					let message = (element.state == '') ? 'Field required !' : 'Special characters & space are not allowed !'
					element.empty = true
					that.notifyMe(message, 'warn')
				}
			},
			
			GetStatusInfo() {
				let that = this
				
				let url   = 'get_user_info'
				let method= 'GET'
				let data  = {}
				that.loadstatus = true
				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					
					that.myArrayStatus = []
					that.loadstatus = false
					response.data.status.forEach( function(entry) {
						that.myArrayStatus.push({
							id		: entry.id,
							state	: entry.state,
							order	: entry.order,
							status	: entry.status,
							defaut	: entry.defaut,
							color	: entry.color,
							empty	: false,
							newField: false,
							removable	: entry.removable,
							is_qualified: entry.is_qualified,
						})
						// if( entry.defaut == 1 ) {
							// that.SelectedStatus = entry.id
						// }
					} );
					that.SaveStatus = false
				}).catch(( {response} ) => {
					console.log(response)
					that.loadstatus = false
				})
				
			},
			
		},
		mounted() {
			this.GetStatusInfo()
		},
		
		filters: {
			
		},
		
	}
</script>

<style lang="scss">
	.oml-statusHead-tr{
		border-bottom: 1px solid #e7eaf3;
		padding:12px 0;
		
		.oml-status-th{
			color: #8c8c8c;
			font-weight: normal;
			font-size: 14px;
		}
	}
	
	.oml-statusBody-tr{
		width:100%;background-color: #ffffff;
		
		&:hover{
			box-shadow: 0px 0px 30px -14px #000000;
			-webkit-box-shadow: 0px 0px 30px -14px #000000;
		}
		
		.oml-status-td{
		
		}
	}
</style>
