<template>
    <div class="row dispatch-md">
        <div class="col-8 mx-auto">
            <div class="control-group form-group">
                <h4 style="color: rgb(49, 50, 52); font-weight: bold; margin-bottom: 2rem;" class="col-md-12">{{ $t('DispatchSettings')}}</h4>
                <div class="col-md-12">
                    
					<div class="row">
                        <div class="control-group form-group col-md-12">
                            <label class="control-label label-md">{{ $t('ChooseTheStatus')}}</label>
                            <i class="fa fa-info-circle" title="Status to exclude from dispatch" v-b-popover.hover.top="'Choose which leads will not be dispatched to your team according to their status'"></i>
                            <div>
                                <vue-multiselect 
									:multiple="true" 
									label="name" 
									track-by="id" 
									v-model="settingArray.excludeStatus" 
									:options="myArrayStatus" 
									:close-on-select="false" 
									:clear-on-select="false" :preserve-search="true" placeholder="Pick Some Status"><!--  :preselect-first="true" :hide-selected="true" -->
								</vue-multiselect>
                            </div>
                        </div>
                        <div class="control-group form-group col-md-12">
                            <label class="control-label label-md">{{$t('ChooseExclude')}}</label>
                            <i class="fa fa-info-circle" title="Sources to exclude from dispatch" v-b-popover.hover.top="'Choose which leads will not be dispatched to your team according to your sources'"></i>
                            <div>
								<vue-multiselect 
									:multiple="true" 
									label="name" 
									track-by="id" 
									v-model="settingArray.excludeSources" 
									:options="mySourcesNow" 
									:close-on-select="false" 
									:clear-on-select="false" :preserve-search="true" :placeholder="$t('PickSomeStatus')"><!--  :preselect-first="true" -->
								</vue-multiselect>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="control-label label-md">{{$t('LeadLockTime')}}</label>
                                    <input class="form-control" type="number" v-model="settingArray.lock" min="1" required>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>{{ $t('MaxNumber')}}</label>
                                    <input type="number" class="form-control" v-model="settingArray.unreachableCall" min="1" :placeholder="$t('number')">
                                </div>
                            </div>
                        </div>
						
                        <div class="control-group form-group col-md-12">
                            <label class="control-label label-md">{{$t('IfUnreachable')}}</label>
							<i class="fa fa-info-circle" v-b-popover.hover.top="'Use 0 to not schadule a next call when lead is unreachable'"></i>
							<input class="form-control" type="number" min="1" v-model="settingArray.intervalCall" required>
                        </div>
						
                        <div class="col-md-12">
                            <div class="row">
								<div class="col-md-6">
									
									<label class="control-label label-md">{{ $t('DispatchCallBacksTo')}}</label>
									<i class="fa fa-info-circle" v-b-popover.hover.top="'When a lead asks for callback, redispatch the next call to the same agent or any free agent'"></i>
									<div class="form-control form-control-lg small-panel">
										<label class="radio-inline mr-3" for="One" style="vertical-align: sub;font-weight: 500;">
											<input type="radio" id="One" value="1" name="inlineRadioOptions" v-model="settingArray.picked" style="vertical-align: middle; margin-right: 15px;">{{$t('SameAgent')}}
										</label>
										<label class="radio-inline" for="Two" style="vertical-align: sub;font-weight: 500;">
											<input type="radio" id="Two" value="0" name="inlineRadioOptions" v-model="settingArray.picked" style="vertical-align: middle; margin-right: 15px;">{{$t('Anyone')}}
										</label>
									</div>
									
								</div>
								
								<div class="col-md-6">
									<label class="control-label label-md">{{$t('WhichLeads')}}</label>
									<div class="form-control form-control-lg small-panel">
										<label class="radio-inline mr-3" for="stwo" style="vertical-align: sub;font-weight: 500;">
											<input type="radio" id="stwo" value="asc" name="inlineRadioOptions1" v-model="settingArray.sort" style="vertical-align: middle; margin-right: 15px;">{{ $t('CallOldestFirst')}}
										</label>
										<label class="radio-inline" for="sone" style="vertical-align: sub;font-weight: 500;">
											<input type="radio" id="sone" value="desc" name="inlineRadioOptions1" v-model="settingArray.sort" style="vertical-align: middle; margin-right: 15px;">{{ $t('CallNewstFirst')}}
										</label>
									</div>
								</div>
                            </div>
                        </div>
						
                        <div class="mt-2 text-right col-md-12">
							<b-button style="width: 8em;" variant="primary" size="lg" @click="saveSetting">
								<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{$t('Save')}}
							</b-button>
                        </div>
						
                    </div>
					
                </div>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
    import vueMultiselect from 'vue-multiselect'
    export default {
        data() {
			return {
				settingArray: {
					listStatus		: [],
					excludeStatus	: [],
					listSources		: [],
					excludeSources	: [],
					lock			: "",
					unreachableCall	: "",
					intervalCall	: "",
					picked			: "",
					sort			: "",
					treat			: 0,
					id				: 0,
				},
				loadingAction: false,
				loadstatus:false,
				myArrayStatus:[],
				mySourcesNow:[]
			}
		},
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
				GetStatusInfo() {
				let that = this
				
				let url   = 'get_user_info'
				let method= 'GET'
				let data  = {}
				that.loadstatus = true
				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					
					that.myArrayStatus = []
					that.loadstatus = false
					response.data.status.forEach( function(entry) {
						that.myArrayStatus.push({
							value		: entry.id,
							name	: entry.state
							
						})
						// if( entry.defaut == 1 ) {
							// that.SelectedStatus = entry.id
						// }
					} );
					that.SaveStatus = false
				}).catch(( {response} ) => {
					console.log(response)
					that.loadstatus = false
				})
				
			},
			saveSetting() {
				let that = this
				that.loadingAction = true
				let url   = '/lead/update_settings'
				let method= 'POST'
				let data  = {
					workspace_id 			: JSON.parse(localStorage.getItem('oml-client')).id,
					interval : that.settingArray.intervalCall,
					status					: that.settingArray.listStatus,
					lock : that.settingArray.lock,
					unreachablecount : that.settingArray.unreachableCall,
					sort : that.settingArray.sort,
					sources : that.settingArray.listSources

				}
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
					// console.log(response);
					that.notifyMe('Setting Successfully Saved !', 'success')
					that.loadingAction = false
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingAction = false
				})
				
			},
			getDispatchSetting() {
				alert(JSON.parse(localStorage.getItem('oml-client')).id)
				let that = this
				
				let url   = 'get_dispatch_setting'
				let method= 'POST'
				let data  = {setting : that.settingArray}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					let dispatchSetting = response.data.dispatchSetting
					let statusParams	= response.data.statusParams
					let mySources		= response.data.mySources
					var arrayStatus 	= [], arraySources = []
					// console.log(dispatchSetting)
					statusParams.forEach( function(el) {
						that.settingArray.listStatus.push({id : el.id, name : el.state})
						arrayStatus[el.id] = el.state
					} );
					dispatchSetting.state.forEach( function(el) {
						that.settingArray.excludeStatus.push({id : el, name : arrayStatus[el]})
					} );
					
					mySources.forEach( function(el) {
						that.settingArray.listSources.push({id : el.id, name : el.name})
						arraySources[el.id] = el.name
					} );
					dispatchSetting.sources.forEach( function(el) {
						that.settingArray.excludeSources.push({id : el, name : arraySources[el]})
					} );
					that.settingArray.lock				= dispatchSetting.lock
					that.settingArray.unreachableCall	= dispatchSetting.unreachableCall
					that.settingArray.intervalCall		= dispatchSetting.interval
					that.settingArray.picked			= dispatchSetting.picked
					that.settingArray.sort				= dispatchSetting.sort
					that.settingArray.treat 			= dispatchSetting.treat
					that.settingArray.id                = dispatchSetting.id
					
					
				}).catch((  ) => {
					
				})
				
			},	
			getSources(action = '') {
				let that = this
				// that.arrayResult.data = []
				console.log(action);
				
				
				let url   = 'sources'
				let method= 'POST'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					
					let mySources = response.data.mySources.data;
					mySources.forEach( function(sourceData) {
						that.mySourcesNow.push(
							{
								id 				 : sourceData.id,
								name 			 : sourceData.name,
								
							}
						)
					} );
					
					that.loadingData = false
					
				}).catch(( {response} ) => {
					console.log(response)
					that.loadingData = false
				})
				
			},
		},
		
		mounted() {
			//this.getDispatchSetting(),
			this.GetStatusInfo(),
			this.getSources()
		},

		computed: {
			
		},

		components: {
			//
			vueMultiselect
		}
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
	.multiselect__tag-icon:after {
		color: #ffffff;
	}
	
	.multiselect__tag {
		background: #007bff;
	}
	
	.multiselect__option--highlight {
		background: #007bff;
		&:after {
			background: #007bff;
		}
	}
	
	.multiselect__tag-icon:hover {
		background-color: #007bff;
	}
	
	.label-md {
		font-size: 14px;
		font-weight: 500;
	}
	
	.dispatch-md {
		.fa-info-circle {
			vertical-align: middle;
			padding-left: 4px;
			color: #565656;
		}
	}
	
</style>